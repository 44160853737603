import React from "react";

const SectionHeader = ({ title }) => {
  return (
    <section className="relative bg-primary-gradient">
      <div className="container mx-auto py-10 px-4">
        <h1 className="text-4xl md:text-6xl font-bold text-center text-white">{title}</h1>
      </div>
    </section>
  );
};

export default SectionHeader;
