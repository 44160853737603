import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import SectionHeader from "../components/SectionHeader";
import PlayerCard from "../components/PlayerCard";
import StaffCard from "../components/StaffCard";

const TeamDetailsPage = ({ club }) => {
  const location = useLocation();
  const { team } = location.state || {};
  const documentId = team?.documentId;

  const [players, setPlayers] = useState([]);
  const [trainers, setTrainers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRelatedData = async () => {
      if (!documentId) {
        console.error("Document ID is missing.");
        return;
      }

      try {
        const playersResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/players?filters[team][documentId][$eq]=${documentId}&populate=*`
        );
        setPlayers(playersResponse.data.data || []);

        const trainersResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/trainers?filters[team][documentId][$eq]=${documentId}&populate=*`
        );
        setTrainers(trainersResponse.data.data || []);
      } catch (error) {
        console.error("Error fetching related data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRelatedData();
  }, [documentId]);

  if (loading) return <div className="text-center my-5">Loading...</div>;

  const { Name, Description, Photo } = team || {};
  const teamPhotoUrl = Photo
    ? `${process.env.REACT_APP_API_URL}${
        Photo.formats?.medium?.url || Photo.url
      }`
    : null;

  return (
    <div>
      {teamPhotoUrl && (
        <div className="relative w-full max-h-[80vh] overflow-hidden bg-gradient-to-r from-primary to-dark">
          <img
            src={teamPhotoUrl}
            alt={`${Name} team`}
            className="w-full h-full object-cover object-top"
            style={{ maxHeight: "80vh" }}
          />
        </div>
      )}
      <SectionHeader title={`${Name}`} />
      <section className="my-10">
        <div className="container mx-auto">
          <div>
            <p className="mb-6 px-6 md:px-0" style={{ textAlign: "justify" }}>
              {Description}
            </p>
          </div>

          <h2 className="text-4xl font-bold mb-4 mt-10 mb-5 text-center md:text-left">
            Staff
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6">
            {trainers.length > 0 ? (
              trainers.map((trainer) => (
                <StaffCard key={trainer.id} staff={trainer} />
              ))
            ) : (
              <p className="text-gray-500">
                No staff members found for this team.
              </p>
            )}
          </div>

          <h2 className="text-4xl font-bold mb-4 mt-10 mb-5">Joueurs</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6">
            {players.length > 0 ? (
              players.map((player) => (
                <PlayerCard key={player.id} player={player} />
              ))
            ) : (
              <p className="text-gray-500">No players found for this team.</p>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default TeamDetailsPage;
