import React, { useEffect, useState } from "react";
import axios from "axios";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import TeamDetailsPage from "./pages/TeamDetailsPage";
import SponsorsPage from "./pages/SponsorsPage";
import BlogPage from "./pages/BlogPage";
import BlogPostPage from "./pages/BlogPostPage";
import CommitteePage from "./pages/CommitteePage";
import HomePage from "./pages/HomePage";
import ContactPage from "./pages/ContactPage";
import CalendarPage from "./pages/CalendarPage";
import MatchDetailsPage from "./pages/MatchDetailsPage";
import NotFoundPage from "./components/404";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import gamesData from "./data/full_calendar.json";
import StadiumPage from "./pages/StadiumPage";
import HistoryPage from "./pages/HistoryPage";
import RefereesPage from "./pages/RefereesPage";
import TrainersPage from "./pages/TrainersPage";

const App = () => {
  const [club, setClub] = useState(null);
  const [contact, setContact] = useState(null);
  const [homepage, setHomepage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [matches] = useState(gamesData);

  const getSubdomain = () => {
    const hostname = window.location.hostname;
    const parts = hostname.split(".");
    if (parts.length > 2) {
      return parts[0].toLowerCase();
    }
    return null;
  };

  const normalizeString = (str) =>
    str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "") // Remove accents
      .replace(/\s+/g, "") // Remove spaces
      .toLowerCase();

  useEffect(() => {
    const fetchData = async () => {
      const subdomain = getSubdomain();

      if (!subdomain) {
        console.warn("No subdomain found. Using default settings.");
        setLoading(false);
        return;
      }

      try {
        const clubResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/clubs?populate=*`
        );
        const clubs = clubResponse.data?.data || [];
        const clubData = clubs.find((club) => {
          const slug = normalizeString(club.Name || "");
          return slug === subdomain;
        });

        if (clubData) {
          setClub(clubData);
          setContact(clubData.contact || null);

          // Fetch the homepage data associated with the club
          const homepageResponse = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/homepages?populate=*&filters[club][id][$eq]=${clubData.id}`
          );
          const homepageData = homepageResponse.data?.data?.[0] || null;
          setHomepage(homepageData);

          // Set primary colors dynamically
          document.documentElement.style.setProperty(
            "--color-primary",
            clubData.PrimaryColor || "#007BFF"
          );
          document.documentElement.style.setProperty(
            "--color-secondary",
            clubData.SecondaryColor || "#6C757D"
          );
          document.documentElement.style.setProperty(
            "--color-light",
            clubData.LightColor || "#F8F9FA"
          );
          document.documentElement.style.setProperty(
            "--color-dark",
            clubData.DarkColor || "#343A40"
          );

          // Set favicon dynamically if logo exists
          if (clubData.Logo?.url) {
            const favicon = document.getElementById("favicon");
            if (favicon) {
              favicon.href = `${process.env.REACT_APP_API_URL}${clubData.Logo.url}`;
            }
          }
        } else {
          console.error(
            "No matching club found for subdomain:",
            subdomain,
            "Available clubs:",
            clubs.map((c) => c.Name)
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <p>Loading data...</p>;
  if (!club) return <p>No club found for this subdomain.</p>;

  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Navbar club={club} />
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<HomePage club={club} homepage={homepage} />} />
            <Route path="/sponsors" element={<SponsorsPage club={club} />} />
            <Route path="/teams/:teamId" element={<TeamDetailsPage club={club} />} />
            <Route path="/blog" element={<BlogPage club={club} />} />
            <Route path="/blog/:slug" element={<BlogPostPage club={club} />} />
            <Route path="/comite" element={<CommitteePage club={club} />} />
            <Route path="/calendrier" element={<CalendarPage matches={matches} />} />
            <Route path="/contact" element={<ContactPage contact={contact} />} />
            <Route path="/calendrier/:matchId" element={<MatchDetailsPage />} />
            <Route path="/stade" element={<StadiumPage club={club} />} />
            <Route path="/arbitres" element={<RefereesPage club={club} />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="/entraineurs" element={<TrainersPage club={club} />} />
            <Route path="/histoire" element={<HistoryPage club={club} />} />
            </Routes>
        </div>
        <Footer club={club} />
      </div>
    </Router>
  );
};

export default App;
