import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import SectionHeader from "../components/SectionHeader";
import dayjs from "dayjs";
import ReactMarkdown from "react-markdown";

const BlogPage = ({ club }) => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = `Blog - ${club.Nom}`;

    const fetchArticles = async () => {
      if (!club?.id) return;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/articles?populate=*&filters[club][id][$eq]=${club.id}`
        );
        setArticles(response.data.data || []);
      } catch (error) {
        console.error("Error fetching articles:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, [club]);

  if (loading) return <div>Loading articles...</div>;

  if (articles.length === 0) {
    return (
      <div className="text-center my-5">
        No articles found for {club?.Nom}.
      </div>
    );
  }

  return (
    <div>
      <SectionHeader title="Blog" />
      <div className="container mx-auto my-10 px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
          {articles.map((article) => {
            const {
              id,
              Title,
              slug,
              Content,
              Thumbnail,
              Publication,
              Category,
            } = article;

            const thumbnailUrl = Thumbnail?.formats?.thumbnail?.url
              ? `${process.env.REACT_APP_API_URL}${Thumbnail.formats.thumbnail.url}`
              : null;

            const formattedDate = Publication
              ? dayjs(Publication).format("DD.MM.YYYY")
              : "Unknown Date";

            // Parse Markdown content for preview
            const markdownPreview = Content
              ? Content.split("\n").slice(0, 2).join(" ") // Use first 2 lines as preview
              : "No content available.";

            return (
              <div
                key={id}
                className="bg-white border rounded-lg overflow-hidden flex flex-col h-full shadow-md"
              >
                {thumbnailUrl && (
                  <img
                    src={thumbnailUrl}
                    className="w-full h-60 object-cover"
                    alt={Title}
                  />
                )}
                <div className="p-5 flex flex-col justify-between flex-grow">
                  <div className="flex justify-between items-center mb-3">
                    <p className="text-gray-500 text-sm">{formattedDate}</p>
                    {Category && (
                      <span className="text-xs font-semibold bg-gray-200 text-gray-700 py-1 px-2 rounded">
                        {Category}
                      </span>
                    )}
                  </div>
                  <h5 className="text-xl font-semibold mb-3">{Title}</h5>
                  <ReactMarkdown className="text-gray-700 mb-4 line-clamp-3">
                    {markdownPreview}
                  </ReactMarkdown>
                  <div className="mt-auto">
                    <Link
                      to={`/blog/${slug}`}
                      state={{ article }} // Pass the article object to BlogPostPage
                      className="inline-block bg-primary text-white py-2 px-4 rounded-lg hover:bg-primary-dark transition duration-200"
                    >
                      En savoir plus
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
