import React, { useEffect, useState } from "react";
import axios from "axios";
import SectionHeader from "../components/SectionHeader";

const TrainersPage = ({ club }) => {
  const [trainers, setTrainers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTrainers = async () => {
      if (!club?.id) return;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/trainers?populate=team&filters[club][id][$eq]=${club.id}`
        );
        setTrainers(response.data?.data || []);
      } catch (error) {
        console.error("Error fetching trainers:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTrainers();
  }, [club]);

  if (loading) return <div className="text-center my-10">Loading trainers...</div>;
  if (trainers.length === 0)
    return <div className="text-center my-10">No trainers found for {club?.Name}.</div>;

  return (
    <div>
      <SectionHeader title="Entraîneurs" />
      <div className="container mx-auto my-10 px-4">
        <table className="min-w-full border-collapse border border-gray-200">
          <thead>
            <tr className="bg-gray-100">
              <th className="border border-gray-200 px-4 py-2 text-left">Nom</th>
              <th className="border border-gray-200 px-4 py-2 text-left">Email</th>
              <th className="border border-gray-200 px-4 py-2 text-left">Téléphone</th>
              <th className="border border-gray-200 px-4 py-2 text-left">Fonction</th>
              <th className="border border-gray-200 px-4 py-2 text-left">Équipe</th>
            </tr>
          </thead>
          <tbody>
            {trainers.map((trainer) => {
              const teamName = trainer.team?.Name || "N/A";
              return (
                <tr key={trainer.id} className="hover:bg-gray-50">
                  <td className="border border-gray-200 px-4 py-2">
                    {trainer.Name} {trainer.Surname}
                  </td>
                  <td className="border border-gray-200 px-4 py-2">
                    <a
                      href={`mailto:${trainer.Email}`}
                      className="text-primary hover:underline"
                    >
                      {trainer.Email}
                    </a>
                  </td>
                  <td className="border border-gray-200 px-4 py-2">{trainer.Phone}</td>
                  <td className="border border-gray-200 px-4 py-2">{trainer.Function}</td>
                  <td className="border border-gray-200 px-4 py-2">{teamName}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TrainersPage;
