import React from "react";
import getTeamLogo from "../utils/getTeamLogo"; // Import the logo utility

const MatchHeader = ({ matchDetails }) => {
    if (!matchDetails) return null;

    const { teams, scoreTeamA, scoreTeamB } = matchDetails;

    return (
        <div className="bg-primary text-white p-6 flex flex-col items-center">
            <div className="flex items-center justify-center w-full max-w-4xl">
                <div className="flex items-center justify-end w-2/5 md:w-1/3">
                    <p className="text-lg font-bold mr-2 hidden md:block">{teams[0].teamName}</p>
                    <img
                        src={getTeamLogo(teams[0].teamName)}
                        alt={teams[0].teamName}
                        className="w-16 h-16"
                        onError={(e) => (e.target.src = "/team-logos/default.png")}
                    />
                </div>

                <div className="flex flex-col items-center justify-center w-1/5 md:w-1/3">
                    <h1 className="text-4xl font-extrabold">
                        {scoreTeamA !== null && scoreTeamB !== null ? `${scoreTeamA} - ${scoreTeamB}` : "VS"}
                    </h1>
                </div>

                <div className="flex items-center justify-start w-2/5 md:w-1/3">
                    <img
                        src={getTeamLogo(teams[1].teamName)}
                        alt={teams[1].teamName}
                        className="w-16 h-16 mr-2"
                        onError={(e) => (e.target.src = "/team-logos/default.png")}
                    />
                    <p className="text-lg font-bold hidden md:block">{teams[1].teamName}</p>
                </div>
            </div>
        </div>
    );
};

export default MatchHeader;
