const cleanTeamName = (teamName) => {
    if (!teamName) return "";
  
    // Remove everything between parentheses and the parentheses themselves
    let cleanedName = teamName.replace(/\(.*?\)/g, "").replace(/[()]/g, "");
  
    // Remove all numbers and specific patterns like MXX
    cleanedName = cleanedName.replace(/\bM\d+\b/g, ""); // Remove patterns like M16, MXX
    cleanedName = cleanedName.replace(/[0-9]/g, ""); // Remove any remaining standalone numbers
  
    return cleanedName.trim(); // Trim any extra spaces
  };
  
  const getTeamLogo = (teamName) => {
    if (!teamName) return null;
  
    // Clean the team name
    const cleanedName = cleanTeamName(teamName);
  
    // Convert cleaned team name to lowercase, remove spaces, and special characters
    const formattedName = cleanedName
      .normalize("NFD") // Normalize to remove diacritics
      .replace(/[\u0300-\u036f]/g, "") // Remove diacritical marks
      .replace(/\s+/g, "") // Remove spaces
      .replace(/[^a-zA-Z]/g, "") // Remove special characters (numbers already removed)
      .toLowerCase();
  
    // Return the logo path
    return `/team-logos/${formattedName}.png`;
  };
  
  export default getTeamLogo;
  