import React from 'react';
import { Link } from 'react-router-dom'; // Optional, if you're using React Router

const NotFoundPage = () => {
  return (
    <div className="h-screen flex flex-col justify-center items-center text-gray-800">
      <h1 className="text-6xl font-bold mb-4">404</h1>
      <p className="text-xl mb-6">Oups ! La page que vous cherchez n'existe pas.</p>
      <Link
        to="/" // Change this to your homepage route
        className="bg-primary text-white px-6 py-3 rounded-lg hover:bg-primary-dark"
      >
        Retour à l'accueil
      </Link>
    </div>
  );
};

export default NotFoundPage;
