import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import SectionHeader from "../components/SectionHeader";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const ContactPage = ({ contact }) => {
  if (!contact) {
    return (
      <div className="text-center my-10 text-lg">
        Contact information could not be retrieved.
      </div>
    );
  }

  const { Address, Phone, Email, Latitude, Longitude, Place, NPA } = contact;

  return (
    <div>
      <SectionHeader title="Contact" />
      <div className="container mx-auto my-10 px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h3 className="text-xl font-medium mb-2">Adresse</h3>
            <p className="mb-4">
              {Address || "No address available"}, {NPA} {Place}
            </p>

            <h3 className="text-xl font-medium mb-2">Téléphone</h3>
            <p className="mb-4">{Phone || "No phone number available"}</p>

            <h3 className="text-xl font-medium mb-2">Email</h3>
            <p className="mb-4">
              {Email ? (
                <a href={`mailto:${Email}`} className="text-primary underline">
                  {Email}
                </a>
              ) : (
                "No email available"
              )}
            </p>
          </div>

          <div>
            {Latitude && Longitude ? (
              <MapContainer
                center={[Latitude, Longitude]}
                zoom={15}
                style={{
                  height: "300px",
                  width: "100%",
                  zIndex: 0, // Ensure the map is behind other elements
                }}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution="&copy; <a href='https://osm.org/copyright'>OpenStreetMap</a> contributors"
                />
                <Marker position={[Latitude, Longitude]}>
                  <Popup>{Address || "Stadium Location"}</Popup>
                </Marker>
              </MapContainer>
            ) : (
              <p className="text-center text-gray-600">
                No map available for this address.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};


export default ContactPage;
