import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const createSlug = (name) => name.toLowerCase().replace(/\s+/g, "-");

const Navbar = ({ club }) => {
  const [teams, setTeams] = useState([]);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isClubDropdownOpen, setIsClubDropdownOpen] = useState(false);
  const [isTeamsDropdownOpen, setIsTeamsDropdownOpen] = useState(false);

  const clubDropdownRef = useRef(null);
  const teamsDropdownRef = useRef(null);

  useEffect(() => {
    const fetchTeams = async () => {
      if (!club?.id) return;
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/teams?populate=club`
        );
        const allTeams = response.data.data;
        const filteredTeams = allTeams.filter(
          (team) => team.club?.id === club.id
        );
        setTeams(filteredTeams);
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    };
    fetchTeams();
  }, [club]);

  const logoUrl = club?.Logo
    ? `${process.env.REACT_APP_API_URL}${club.Logo.url}`
    : null;
  const clubName = club?.Name || "All4Club";

  const toggleSidebar = () => setIsNavOpen(!isNavOpen);
  const closeSidebar = () => setIsNavOpen(false);

  useEffect(() => {
    const handleClickOutsideDropdowns = (event) => {
      if (
        window.innerWidth >= 1024 &&
        !clubDropdownRef.current?.contains(event.target) &&
        !teamsDropdownRef.current?.contains(event.target)
      ) {
        setIsClubDropdownOpen(false);
        setIsTeamsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideDropdowns);
    return () =>
      document.removeEventListener("mousedown", handleClickOutsideDropdowns);
  }, []);

  return (
    <nav className="bg-white border-b border-secondary z-50 relative">
      <div className="container mx-auto px-4 py-3 flex justify-between items-center">
        <Link className="flex items-center" to="/" onClick={closeSidebar}>
          {logoUrl && (
            <>
              <img src={logoUrl} alt="Club Logo" className="h-8 w-auto mr-2" />
              <span className="text-lg font-bold text-gray-800">
                {clubName}
              </span>
            </>
          )}
        </Link>

        <button
          className="sidebar-toggle text-gray-800 lg:hidden"
          onClick={toggleSidebar}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>

        {/* Sidebar */}
        <div
          className={`fixed top-0 left-0 w-3/4 h-full bg-white z-[1000] shadow-lg transform ${
            isNavOpen ? "translate-x-0" : "-translate-x-full"
          } transition-transform duration-300 lg:hidden`}
        >
          <div className="p-4 flex justify-between items-center border-b border-gray-200">
            <span className="text-lg font-bold text-gray-800">{clubName}</span>
            <button className="text-gray-800 text-2xl" onClick={closeSidebar}>
              &times;
            </button>
          </div>
          <ul className="p-4">
            <li>
              <Link
                className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                to="/"
                onClick={closeSidebar}
              >
                Accueil
              </Link>
            </li>
            <li>
              <Link
                className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                to="/calendrier"
                onClick={closeSidebar}
              >
                Calendrier
              </Link>
            </li>
            <li>
              <button
                className="w-full text-left block px-4 py-2 text-gray-700 hover:bg-gray-100"
                onClick={() => {
                  setIsClubDropdownOpen(!isClubDropdownOpen);
                  setIsTeamsDropdownOpen(false);
                }}
              >
                Club
              </button>
              {isClubDropdownOpen && (
                <ul className="ml-4 border-l border-gray-200">
                  <li>
                    <Link
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                      to="/comite"
                      onClick={closeSidebar}
                    >
                      Comité
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                      to="/histoire"
                    >
                      Histoire
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                      to="/entraineurs"
                    >
                      Entraîneurs
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                      to="/arbitres"
                    >
                      Arbitres
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                      to="/stade"
                    >
                      Stade
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <button
                className="w-full text-left block px-4 py-2 text-gray-700 hover:bg-gray-100"
                onClick={() => {
                  setIsTeamsDropdownOpen(!isTeamsDropdownOpen);
                  setIsClubDropdownOpen(false);
                }}
              >
                Equipes
              </button>
              {isTeamsDropdownOpen && (
                <ul className="ml-4 border-l border-gray-200">
                  {teams.map((team) => (
                    <li key={team.id}>
                      <li key={team.id}>
                        <Link
                          className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                          to={`/teams/${createSlug(team.Name)}`}
                          state={{ team }} // Ensure the team object is passed here
                          onClick={closeSidebar}
                        >
                          {team.Name}
                        </Link>
                      </li>
                    </li>
                  ))}
                </ul>
              )}
            </li>
            <li>
              <Link
                className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                to="/blog"
                onClick={closeSidebar}
              >
                Blog
              </Link>
            </li>
            <li>
              <Link
                className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                to="/sponsors"
                onClick={closeSidebar}
              >
                Sponsors
              </Link>
            </li>
            <li>
              <Link
                className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                to="/contact"
                onClick={closeSidebar}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>

        {/* Desktop Menu */}
        <div className="hidden lg:flex lg:items-center">
          <ul className="flex space-x-6 items-center text-gray-700">
            <li>
              <Link
                className="hover:text-primary transition-colors duration-200"
                to="/"
              >
                Accueil
              </Link>
            </li>
            <li>
              <Link
                className="hover:text-primary transition-colors duration-200"
                to="/calendrier"
              >
                Calendrier
              </Link>
            </li>
            <li className="relative" ref={clubDropdownRef}>
              <button
                className="hover:text-primary transition-colors duration-200 flex items-center"
                onClick={() => {
                  setIsClubDropdownOpen(!isClubDropdownOpen);
                  setIsTeamsDropdownOpen(false);
                }}
              >
                Club
                <svg
                  className="ml-1 w-4 h-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </button>
              <ul
                className={`absolute left-0 mt-2 w-40 bg-white border border-gray-200 rounded-lg shadow-lg ${
                  isClubDropdownOpen ? "block" : "hidden"
                }`}
              >
                <li>
                  <Link
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                    to="/comite"
                  >
                    Comité
                  </Link>
                </li>
                <li>
                  <Link
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                    to="/histoire"
                  >
                    Histoire
                  </Link>
                </li>
                <li>
                  <Link
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                    to="/entraineurs"
                  >
                    Entraîneurs
                  </Link>
                </li>
                <li>
                  <Link
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                    to="/arbitres"
                  >
                    Arbitres
                  </Link>
                </li>
                <li>
                  <Link
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                    to="/stade"
                  >
                    Stade
                  </Link>
                </li>
              </ul>
            </li>
            <li className="relative" ref={teamsDropdownRef}>
              <button
                className="hover:text-primary transition-colors duration-200 flex items-center"
                onClick={() => {
                  setIsTeamsDropdownOpen(!isTeamsDropdownOpen);
                  setIsClubDropdownOpen(false);
                }}
              >
                Equipes
                <svg
                  className="ml-1 w-4 h-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </button>
              <ul
                className={`absolute left-0 mt-2 w-44 bg-white border border-gray-200 rounded-lg shadow-lg ${
                  isTeamsDropdownOpen ? "block" : "hidden"
                }`}
              >
                {teams.map((team) => (
                  <li key={team.id}>
                    <li key={team.id}>
                      <Link
                        className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                        to={`/teams/${createSlug(team.Name)}`}
                        state={{ team }} // Ensure the team object is passed here
                        onClick={closeSidebar}
                      >
                        {team.Name}
                      </Link>
                    </li>
                  </li>
                ))}
              </ul>
            </li>
            <li>
              <Link
                className="hover:text-primary transition-colors duration-200"
                to="/blog"
              >
                Blog
              </Link>
            </li>
            <li>
              <Link
                className="hover:text-primary transition-colors duration-200"
                to="/sponsors"
              >
                Sponsors
              </Link>
            </li>
            <li>
              <Link
                className="hover:text-primary transition-colors duration-200"
                to="/contact"
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
