import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareFacebook,
  faSquareInstagram,
  faLinkedin,
  faSquareYoutube,
} from "@fortawesome/free-brands-svg-icons";

const Footer = ({ club }) => {
  if (!club) return null;

  const { Name: ClubName, Description: Description, Logo, contact  } = club;
  const { PrimaryColor, SecondaryColor, DarkColor } = club;

  return (
    <footer
      className="bg-white mt-10 border-t"
      style={{ borderColor: SecondaryColor }}
    >
      <div className="container mx-auto py-8 grid grid-cols-1 md:grid-cols-5 gap-4 text-center">
        <div className="flex flex-col items-center md:items-start">
          <div className="flex items-center space-x-2 mb-2 justify-center md:justify-start">
            {Logo && (
              <img
                src={`${process.env.REACT_APP_API_URL}${Logo.url}`}
                alt="Club Logo"
                className="w-8 h-8 object-contain"
              />
            )}
            <h2 className="text-lg font-semibold">{ClubName}</h2>
          </div>
          <p className="text-gray-600 text-sm md:text-left px-5 md:px-0">
          {Description}
          </p>
        </div>

        <div>
          <h3 className="text-lg font-semibold mb-2">Club</h3>
          <ul className="text-sm">
            <li>A venir</li>
            <li>A venir</li>
            <li>A venir</li>
          </ul>
        </div>

        <div>
          <h3 className="text-lg font-semibold mb-2">Equipes</h3>
          <ul className="text-sm">
            <li>A venir</li>
            <li>A venir</li>
            <li>A venir</li>
          </ul>
        </div>

        <div>
          <h3 className="text-lg font-semibold mb-2">Contact</h3>
          <p className="text-sm">{ClubName}</p>
          <p className="text-sm">{contact?.Address && <p className="text-sm">{contact.Address}</p>}</p>
          <p className="text-sm">{contact?.NPA && contact?.Place && <p className="text-sm">{contact.NPA} {contact.Place}</p>}</p>
        </div>

        <div>
          <h3 className="text-lg font-semibold mb-2">Contact</h3>
          {contact?.Email && (
            <p className="text-sm">
              <a href={`mailto:${contact.Email}`}>{contact.Email}</a>
            </p>
          )}
          {contact?.Phone && <p className="text-sm">{contact.Phone}</p>}
          <div className="flex justify-center space-x-2 mt-2">
            <a href="https://www.facebook.com/share/15TCdpNFgd/?mibextid=LQQJ4d" aria-label="Facebook" target="_blank">
              <FontAwesomeIcon icon={faSquareFacebook} size="lg" />
            </a>
            <a href="https://www.instagram.com/all4club_/profilecard/?igsh=NzB0Z2dzbzg5ZWNl" aria-label="Instagram" target="_blank">
              <FontAwesomeIcon icon={faSquareInstagram} size="lg" />
            </a>  
          </div>
        </div>
      </div>

      <div
        className="py-1 text-center text-white"
        style={{
          background: `linear-gradient(to right, ${PrimaryColor}, ${DarkColor})`,
        }}
      >
        {ClubName} © 2024 tous droits réservés | réalisé par{" "}
        <a
          href="https://all4club.ch"
          target="_blank"
          rel="noopener noreferrer"
          className="underline hover:text-gray-300"
        >
          A4C
        </a>
      </div>
    </footer>
  );
};

export default Footer;
