import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import SectionHeader from "../components/SectionHeader";

const HistoryPage = ({ club }) => {
  const [history, setHistory] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchHistory = async () => {
      if (!club?.id) return;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/histories?populate=*&filters[club][id][$eq]=${club.id}`
        );
        if (response.data?.data.length) {
          setHistory(response.data.data[0]);
        }
      } catch (error) {
        console.error("Error fetching history:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchHistory();
  }, [club]);

  if (loading) return <div className="text-center my-10">Loading history...</div>;
  if (!history) return <div className="text-center my-10">History not found.</div>;

  const { Content } = history || {};

  return (
    <div>
      <SectionHeader title="Histoire du Club" />
      <div className="container mx-auto my-10 px-4 md:max-w-4xl">
        <div className="prose max-w-full">
          <ReactMarkdown remarkPlugins={[remarkGfm]}>{Content}</ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

export default HistoryPage;
