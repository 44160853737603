import React, { useEffect, useState } from "react";
import axios from "axios";
import SectionHeader from "../components/SectionHeader";

const RefereesPage = ({ club }) => {
  const [referees, setReferees] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchReferees = async () => {
      if (!club?.id) return;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/referees?populate=*&filters[club][id][$eq]=${club.id}`
        );
        setReferees(response.data?.data || []);
      } catch (error) {
        console.error("Error fetching referees:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchReferees();
  }, [club]);

  if (loading) return <div className="text-center my-10">Loading referees...</div>;
  if (referees.length === 0)
    return <div className="text-center my-10">No referees found for {club?.Name}.</div>;

  return (
    <div>
      <SectionHeader title="Arbitres" />
      <div className="container mx-auto my-10 px-4">
        <table className="min-w-full border-collapse border border-gray-200">
          <thead>
            <tr className="bg-gray-100">
              <th className="border border-gray-200 px-4 py-2 text-left">Nom</th>
              <th className="border border-gray-200 px-4 py-2 text-left">Email</th>
              <th className="border border-gray-200 px-4 py-2 text-left">Téléphone</th>
              <th className="border border-gray-200 px-4 py-2 text-left">Qualification</th>
            </tr>
          </thead>
          <tbody>
            {referees.map((referee) => (
              <tr key={referee.id} className="hover:bg-gray-50">
                <td className="border border-gray-200 px-4 py-2">
                  {referee.Name} {referee.Surname}
                </td>
                <td className="border border-gray-200 px-4 py-2">
                  <a
                    href={`mailto:${referee.Email}`}
                    className="text-primary hover:underline"
                  >
                    {referee.Email}
                  </a>
                </td>
                <td className="border border-gray-200 px-4 py-2">{referee.Phone}</td>
                <td className="border border-gray-200 px-4 py-2">{referee.Qualification}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RefereesPage;
