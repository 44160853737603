import React from "react";

const StaffCard = ({ staff }) => {
  const staffPhotoUrl = staff.Photo 
    ? `${process.env.REACT_APP_API_URL}${staff.Photo.formats?.medium?.url || staff.Photo.url}` 
    : null;

  return (
    <div className="overflow-hidden border border-gray-200 shadow-md rounded-lg">
      {staffPhotoUrl && (
        <img
          src={staffPhotoUrl}
          alt={`${staff.Name} ${staff.Surname}`}
          className="w-full h-60 object-cover rounded-t-lg"
        />
      )}
      <div className="p-4 bg-white text-center">
        <h3 className="text-lg font-semibold">{staff.Name} {staff.Surname}</h3>
        <p className="text-sm text-gray-500 mt-1">{staff.Function || "Unknown Role"}</p>
        {staff.Phone && <p className="text-sm text-gray-500 mt-1">{staff.Phone}</p>}
        {staff.Email && (
          <p className="text-sm text-gray-500 mt-1">
            <a href={`mailto:${staff.Email}`} className="text-primary underline">
              {staff.Email}
            </a>
          </p>
        )}
      </div>
    </div>
  );
};

export default StaffCard;
