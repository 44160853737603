import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import SectionHeader from "../components/SectionHeader";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const BlogPostPage = ({ club }) => {
  const { slug } = useParams();
  const location = useLocation();
  const [article, setArticle] = useState(location.state?.article || null);
  const [loading, setLoading] = useState(!article);

  useEffect(() => {
    if (article) {
      document.title = `${article.Title} - ${club.Nom}`;
      return;
    }

    const fetchArticle = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/articles?filters[slug][$eq]=${slug}&populate=*`
        );
        if (response.data.data.length) {
          const fetchedArticle = response.data.data[0];
          setArticle(fetchedArticle);
          document.title = `${fetchedArticle.Title} - ${club.Nom}`;
        } else {
          document.title = `Article Not Found - ${club.Nom}`;
        }
      } catch (error) {
        console.error("Error fetching the article:", error);
        document.title = `Error - ${club.Nom}`;
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [slug, club, article]);

  if (loading)
    return <div className="text-center my-10">Loading article...</div>;
  if (!article)
    return <div className="text-center my-10">Article not found.</div>;

  const { Title, Content, Publication, Thumbnail } = article;
  const thumbnailUrl = Thumbnail?.formats?.thumbnail?.url
    ? `${process.env.REACT_APP_API_URL}${Thumbnail.formats.thumbnail.url}`
    : null;

  return (
    <div>
      <SectionHeader title={Title} />
      <div className="container mx-auto my-10 px-4 md:max-w-4xl">
        {thumbnailUrl && (
          <img
            src={thumbnailUrl}
            className="w-full h-auto object-cover rounded-lg mb-5 border"
            alt={Title}
          />
        )}
        <p className="text-gray-500 mb-6">
          {new Date(Publication).toLocaleDateString()}
        </p>
        <div className="prose max-w-full">
          <ReactMarkdown remarkPlugins={[remarkGfm]}>{Content}</ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

export default BlogPostPage;
