import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import SectionHeader from "../components/SectionHeader";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";

const HomePage = ({ club, homepage }) => {
  const [carouselImages, setCarouselImages] = useState([]);

  useEffect(() => {
    document.title = `Accueil - ${club.Name}`;
    setCarouselImages(homepage?.Carousel || []);
  }, [club, homepage]);

  const mainSliderSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    arrows: false,
  };

  return (
    <div className="relative">
      <div className="relative w-full overflow-hidden" style={{ height: "calc(84vh - 48px)" }}>
        <Slider {...mainSliderSettings}>
          {carouselImages.map((image) => (
            <div key={image.id} className="flex h-full">
              <img
                src={`${process.env.REACT_APP_API_URL}${image.url}`}
                alt={image.name || "Carousel Image"}
                className="w-full object-cover"
                style={{ height: "calc(84vh - 48px)" }}
              />
            </div>
          ))}
        </Slider>
      </div>
      <SectionHeader title={`${club.Name}`} />
      {/* Additional homepage content */}
    </div>
  );
};

export default HomePage;
