import React, { useEffect, useState } from "react";
import axios from "axios";
import SectionHeader from "../components/SectionHeader";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Fix the default marker issue in Leaflet with Webpack
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";

const defaultIcon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconAnchor: [12, 41], // Adjust marker icon position
});
L.Marker.prototype.options.icon = defaultIcon;

const StadiumPage = ({ club }) => {
  const [stadium, setStadium] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStadium = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/stadiums?populate=*&filters[club][id][$eq]=${club.id}`
        );
        if (response.data.data.length) {
          setStadium(response.data.data[0]);
        }
      } catch (error) {
        console.error("Error fetching stadium:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchStadium();
  }, [club.id]);

  if (loading) return <div className="text-center my-10">Loading stadium...</div>;
  if (!stadium) return <div className="text-center my-10">Stadium not found.</div>;

  const { Name, Address, NPA, Place, Content, Photos, Latitude, Longitude } = stadium;
  const photoUrl = Photos?.[0]?.url
    ? `${process.env.REACT_APP_API_URL}${Photos[0].url}`
    : null;

  return (
    <div>
      <SectionHeader title={Name} />
      <div className="container mx-auto my-10 px-4 md:max-w-4xl">
        {photoUrl && (
          <img
            src={photoUrl}
            className="w-full h-auto object-cover rounded-lg mb-5 border"
            alt={Name}
          />
        )}
        <p className="text-gray-700 mb-4">{Address}, {NPA} {Place}</p>
        <div className="prose max-w-full mb-10">
          <ReactMarkdown remarkPlugins={[remarkGfm]}>
            {Content || "No additional content available for this stadium."}
          </ReactMarkdown>
        </div>

        {/* Leaflet Map */}
        <div className="w-full h-96">
          {Latitude && Longitude ? (
            <MapContainer
              center={[Latitude, Longitude]}
              zoom={15}
              scrollWheelZoom={false}
              style={{ height: "100%", width: "100%" }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              <Marker position={[Latitude, Longitude]}>
                <Popup>
                  <strong>{Name}</strong>
                  <br />
                  {Address}, {NPA} {Place}
                </Popup>
              </Marker>
            </MapContainer>
          ) : (
            <p className="text-center text-gray-500">No location available for this stadium.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default StadiumPage;
