import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MatchHeader from "../components/MatchHeader";
import matchData from "../data/match_saint-leo_chalais.json";
import matchEventsData from "../data/match_saint-leo_chalais_events.json";
import matchPlayersData from "../data/match_saint-leo_chalais_players.json";
import matchBenchData from "../data/match_saint-leo_chalais_bench.json";
import getTeamLogo from "../utils/getTeamLogo";

const eventIcons = {
  But: "⚽",
  avertissement: "🟨",
  expulsion: "🟥",
  changement: "🔄",
  Autre: "📌",
};

const calculateDynamicScores = (events, teams) => {
  const scores = {
    [teams[0].teamId]: 0,
    [teams[1].teamId]: 0,
  };

  const goalEvents = events.filter((event) => event.eventTypeName === "But");

  const updatedEvents = events.map((event) => {
    if (event.eventTypeName === "But") {
      scores[event.teamId] += 1; // Increment the score for the team
      return {
        ...event,
        score: `${scores[teams[0].teamId]} - ${scores[teams[1].teamId]}`, // Update the score dynamically
      };
    }
    return event;
  });

  return updatedEvents;
};

const MatchDetailsPage = () => {
  const { matchId } = useParams();
  const [matchDetails, setMatchDetails] = useState(null);
  const [matchEvents, setMatchEvents] = useState([]);
  const [players, setPlayers] = useState([]);
  const [bench, setBench] = useState([]);
  const [loading, setLoading] = useState(true);
  const [apiPlayers, setApiPlayers] = useState([]);

  useEffect(() => {
    const fetchApiPlayers = async () => {
      try {
        const response = await fetch("https://cms.a4c.ch/api/teams/?populate=Players.Photo");
        const data = await response.json();
        console.log("API players data:", data.data);
        setApiPlayers(data.data);
      } catch (error) {
        console.error("Error fetching API players:", error);
      }
    };

    fetchApiPlayers();
  }, []);

  useEffect(() => {
    // Load match details and filter data by matchId
    if (Number(matchId) === matchData.matchId) {
      setMatchDetails(matchData);

      // Filter events for the match and calculate scores dynamically
      const filteredEvents = matchEventsData.filter(
        (event) =>
          event.teamId === matchData.teams[0].teamId || event.teamId === matchData.teams[1].teamId
      );

      const eventsWithScores = calculateDynamicScores(filteredEvents, matchData.teams);
      setMatchEvents(eventsWithScores);

      const filteredPlayers = matchPlayersData.filter(
        (player) =>
          player.teamId === matchData.teams[0].teamId || player.teamId === matchData.teams[1].teamId
      );
      setPlayers(filteredPlayers);

      const filteredBench = matchBenchData.filter(
        (person) =>
          person.teamId === matchData.teams[0].teamId || person.teamId === matchData.teams[1].teamId
      );
      setBench(filteredBench);

      setLoading(false);
    }
  }, [matchId, apiPlayers]);

  const getPlayerPhoto = (player, teamName) => {
    if (!teamName || !player.firstname || !player.name) {
      console.warn("Missing data for player or team:", player, teamName);
      return getTeamLogo(teamName || "Default");
    }
  
    // Find the team from the API data matching the team name
    const team = apiPlayers.find(
      (apiTeam) => apiTeam.Name?.trim().toLowerCase() === teamName.trim().toLowerCase()
    );
  
    if (team) {
      // Find the player in the team by matching FirstName and LastName
      const matchingPlayer = team.Players?.find(
        (apiPlayer) =>
          apiPlayer.Name?.trim().toLowerCase() === player.firstname.trim().toLowerCase() &&
          apiPlayer.Surname?.trim().toLowerCase() === player.name.trim().toLowerCase()
      );
  
      if (matchingPlayer?.Photo?.url) {
        // Return the player's photo URL
        const BASE_URL = process.env.REACT_APP_API_URL || "https://cms.a4c.ch";
        return `${BASE_URL}${matchingPlayer.Photo.url}`;
      }
    }
  
    // Default to team logo if no photo is available
    return getTeamLogo(teamName);
  };
    
  
  if (loading) return <p>Loading match details...</p>;

  return (
    <div>
      <MatchHeader matchDetails={matchDetails} />
      <div className="container mx-auto px-4 py-8">
        {/* Match Events */}
        <div className="bg-white p-4 rounded-lg shadow mb-6 lg:w-1/2 mx-auto">
          <h2 className="text-xl font-semibold mb-4">Match Events</h2>
          <ul className="space-y-2">
            {matchEvents.map((event, index) => (
              <li
                key={index}
                className={`flex items-center space-x-2 ${
                  event.teamId === matchDetails.teams[0].teamId
                    ? "justify-start"
                    : "justify-end"
                }`}
              >
                {event.teamId === matchDetails.teams[0].teamId ? (
                  <>
                    <span className="text-lg">
                      {eventIcons[event.eventTypeName] || eventIcons["Autre"]}
                    </span>
                    <span>
                      {event.minute}' -{" "}
                      {event.eventTypeName === "But" && `| ${event.score} `}
                      {event.eventTypeName === "changement"
                        ? `Sortie: ${event.personName} / Entrée: ${event.substitutePlayerName}`
                        : event.personName}
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      {event.eventTypeName === "changement"
                        ? `Sortie: ${event.personName} / Entrée: ${event.substitutePlayerName}`
                        : event.personName}{" "}
                      {event.eventTypeName !== "changement" && `| ${event.minute}'`}
                      {event.eventTypeName === "But" && ` | ${event.score}`}
                    </span>
                    <span className="text-lg">
                      {eventIcons[event.eventTypeName] || eventIcons["Autre"]}
                    </span>
                  </>
                )}
              </li>
            ))}
          </ul>
        </div>

        {/* Players */}
        <div className="bg-white p-4 rounded-lg shadow mb-6 lg:w-1/2 mx-auto">
          <h2 className="text-xl font-semibold mb-4">Players</h2>
          {players.length > 0 ? (
            <>
              {/* Home Team */}
              <div className="mb-6">
                <h3 className="text-lg font-bold mb-4">
                  {matchDetails.teams[0].teamName}
                </h3>
                <div className="flex flex-wrap -mx-2">
                  {players
                    .filter(
                      (player) =>
                        player.teamId === matchDetails.teams[0].teamId
                    )
                    .sort((a, b) => a.jerseyNumber - b.jerseyNumber) // Sort by jersey number
                    .map((player, index) => (
                      <div
                        key={index}
                        className="w-full md:w-1/2 px-2 mb-4"
                      >
                        <div className="flex items-center space-x-4 bg-gray-100 p-4 rounded-lg shadow">
                          <div className="w-16 h-16 rounded-full overflow-hidden border">
                            <img
                              src={getPlayerPhoto(
                                player,
                                matchDetails.teams[0].teamName
                              )}
                              alt={`${player.firstname} ${player.name}`}
                              onError={(e) =>
                                (e.target.src = getTeamLogo(
                                  matchDetails.teams[0].teamName
                                ))
                              }
                              className="w-full h-full object-cover"
                            />
                          </div>
                          <div>
                            <p className="text-lg font-semibold">
                              #{player.jerseyNumber}
                            </p>
                            <p>
                              {player.firstname} {player.name}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              {/* Away Team */}
              <div>
                <h3 className="text-lg font-bold mb-4">
                  {matchDetails.teams[1].teamName}
                </h3>
                <div className="flex flex-wrap -mx-2">
                  {players
                    .filter(
                      (player) =>
                        player.teamId === matchDetails.teams[1].teamId
                    )
                    .sort((a, b) => a.jerseyNumber - b.jerseyNumber) // Sort by jersey number
                    .map((player, index) => (
                      <div
                        key={index}
                        className="w-full md:w-1/2 px-2 mb-4"
                      >
                        <div className="flex items-center space-x-4 bg-gray-100 p-4 rounded-lg shadow">
                          <div className="w-16 h-16 rounded-full overflow-hidden border">
                            <img
                              src={getPlayerPhoto(
                                player,
                                matchDetails.teams[1].teamName
                              )}
                              alt={player.name}
                              onError={(e) =>
                                (e.target.src = getTeamLogo(
                                  matchDetails.teams[1].teamName
                                ))
                              }
                              className="w-full h-full object-cover"
                            />
                          </div>
                          <div>
                            <p className="text-lg font-semibold">
                              #{player.jerseyNumber}
                            </p>
                            <p>
                              {player.firstname} {player.name}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </>
          ) : (
            <p>No players found for this match.</p>
          )}
        </div>

        {/* Bench */}
        <div className="bg-white p-4 rounded-lg shadow lg:w-1/2 mx-auto">
          <h2 className="text-xl font-semibold mb-4">Bench</h2>
          {bench.length > 0 ? (
            <>
              {/* Home Team Bench */}
              <div className="mb-6">
                <h3 className="text-lg font-bold mb-4">
                  {matchDetails.teams[0].teamName}
                </h3>
                <div className="flex flex-wrap -mx-2">
                  {bench
                    .filter(
                      (person) =>
                        person.teamId === matchDetails.teams[0].teamId
                    )
                    .map((person, index) => (
                      <div
                        key={index}
                        className="w-full md:w-1/2 px-2 mb-4"
                      >
                        <div className="flex items-center space-x-4 bg-gray-100 p-4 rounded-lg shadow">
                          <div className="w-16 h-16 rounded-full overflow-hidden border">
                            <img
                              src={getPlayerPhoto(
                                person,
                                matchDetails.teams[0].teamName
                              )}
                              alt={`${person.personName}`}
                              onError={(e) =>
                                (e.target.src = getTeamLogo(
                                  matchDetails.teams[0].teamName
                                ))
                              }
                              className="w-full h-full object-cover"
                            />
                          </div>
                          <div>
                            <p className="text-lg font-semibold">
                              {person.personName}
                            </p>
                            <p>
                              {person.eventSubTypeName}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              {/* Away Team Bench */}
              <div>
                <h3 className="text-lg font-bold mb-4">
                  {matchDetails.teams[1].teamName}
                </h3>
                <div className="flex flex-wrap -mx-2">
                  {bench
                    .filter(
                      (person) =>
                        person.teamId === matchDetails.teams[1].teamId
                    )
                    .map((person, index) => (
                      <div
                        key={index}
                        className="w-full md:w-1/2 px-2 mb-4"
                      >
                        <div className="flex items-center space-x-4 bg-gray-100 p-4 rounded-lg shadow">
                          <div className="w-16 h-16 rounded-full overflow-hidden border">
                            <img
                              src={getPlayerPhoto(
                                person,
                                matchDetails.teams[1].teamName
                              )}
                              alt={`${person.personName}`}
                              onError={(e) =>
                                (e.target.src = getTeamLogo(
                                  matchDetails.teams[1].teamName
                                ))
                              }
                              className="w-full h-full object-cover"
                            />
                          </div>
                          <div>
                            <p className="text-lg font-semibold">
                              {person.personName}
                            </p>
                            <p>
                              {person.eventSubTypeName}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </>
          ) : (
            <p>No bench information available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MatchDetailsPage;
