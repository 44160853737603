import React from "react";

const PlayerCard = ({ player }) => {
  // Correctly access the Photo field
  const playerPhotoUrl = player.Photo 
    ? `${process.env.REACT_APP_API_URL}${player.Photo.formats?.medium?.url || player.Photo.url}` 
    : "/path/to/placeholder-image.png"; // Fallback to placeholder

  return (
    <div className="overflow-hidden border border-gray-200 shadow-md rounded-lg">
      {playerPhotoUrl && (
        <img
          src={playerPhotoUrl}
          alt={`${player.Name} ${player.Surname}`}
          className="w-full h-60 object-cover rounded-t-lg"
        />
      )}
      <div className="p-4 bg-white text-center">
        <h3 className="text-lg font-semibold">{player.Name} {player.Surname}</h3>
      </div>
    </div>
  );
};

export default PlayerCard;
