import React, { useState, useEffect } from "react";
import axios from "axios";
import SectionHeader from "../components/SectionHeader";

const CommitteePage = ({ club }) => {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = `Comité - ${club.Name}`;

    const fetchCommitteeMembers = async () => {
      if (!club?.id) return;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/committees?populate=*&filters[club][id][$eq]=${club.id}`
        );
        setMembers(response.data.data || []);
      } catch (error) {
        console.error("Error fetching committee members:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCommitteeMembers();
  }, [club]);

  if (loading) return <div className="text-center my-5 text-lg">Loading committee members...</div>;

  if (members.length === 0) {
    return <div className="text-center my-5">No committee members found for {club?.Name}.</div>;
  }

  return (
    <div>
      <SectionHeader title="Comité" />
      <div className="container mx-auto my-10 px-4">
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider border-b">
                  Nom & Prénom
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider border-b">
                  Rôle
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider border-b">
                  Email
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider border-b">
                  Téléphone
                </th>
              </tr>
            </thead>
            <tbody>
              {members.map((member) => {
                const { id, Name, Surname, Function, Email, Phone } = member;

                return (
                  <tr key={id} className="bg-white border-b hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {`${Name} ${Surname}`}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {Function || "---"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {Email || "---"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {Phone || "---"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CommitteePage;
