import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import getTeamLogo from "../utils/getTeamLogo"; // Import the utility function
import SectionHeader from "../components/SectionHeader";

const playDayTranslations = {
  Montag: "Lundi",
  Dienstag: "Mardi",
  Mittwoch: "Mercredi",
  Donnerstag: "Jeudi",
  Freitag: "Vendredi",
  Samstag: "Samedi",
  Sonntag: "Dimanche",
};

const matchStateTranslations = {
  "noch nicht ausgetragen" : "Pas encore joué",
  "ausgetragen" : "Joué",
  "forfait" : "Forfait",
  "Null zu Null - Null Punkte" : "0-0 - 0 Points",
  "abgebrochen" : "Interrompu",
  "verschoben" : "Reporté",
  "neu angesetzt" : "Reprogrammé",
  "nicht gespielt (SR)" : "Non joué (Arbitre)",
  "nicht gespielt (Gegner)" : "Non joué (Adversaire)",
  "Spiel findet nicht statt (keine Neuansetzung)" : "Match annulé (pas de reprogrammation)",
  "Abbruch der Saison" : "Arrêt de la saison",
  "Spiel ohne Austragung (keine Publikation)" : "Match non joué (pas de publication)"
};

const matchTypeTranslations = {
  Trainingsspiele: "Match Amical",
  Meisterschaft: "Match de Championnat",
  Cup: "Match de Coupe",
  Firmensportspiele: "Match d'Entreprise",
  Turnier: "Tournoi",
  Hallenturnier: "Tournoi en Salle",
  "Mini-Turniere": "Mini-Tournoi",
  "Schweizer-Cup": "Coupe Suisse",
  "Entscheidungsspiele (Meisters. Modus)": "Match Décisif (Mode Championnat)",
  "Entscheidungsspiele (Cup Modus)": "Match Décisif (Mode Coupe)",
  "LS (Einzelspiele)": "LS (Matchs Individuels)",
  "LS Qualifikation (Meisterschafts-Modus)": "LS Qualification (Mode Championnat)",
  "LS Qualifikation (Cup-Modus)": "LS Qualification (Mode Coupe)",
  "LS Endrunde (Meisterschafts-Modus)": "LS Finale (Mode Championnat)",
  "LS Endrunde (Cup-Modus)": "LS Finale (Mode Coupe)",
};

const leagueNameTranslations = {
  "1. Liga": "1ère Ligue",
  "2. Liga": "2ème Ligue",
  "3. Liga": "3ème Ligue",
  "4. Liga": "4ème Ligue",
  "5. Liga": "5ème Ligue",
  "Junioren C 1. Stärkeklasse" : "Juniors C 1er degré",
  "Junioren C 2. Stärkeklasse" : "Juniors C 2ème degré",
  "Junioren A 2. Stärkeklasse" : "Juniors A 2ème degré",
  "Junioren E" : "Juniors E",
  "Junioren D/9" : "Juniors D/9",
  "Junioren B 2. Stärkeklasse" : "Juniors B 2ème degré",
  "Junioren C Promotion" : "Juniors C Promotion",
  "Junioren B 1. Stärkeklasse" : "Juniors B 1er degré",
};

const formatDate = (dateString) => {
  const date = new Date(dateString);

  // Format day, month, year
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  // Format time (24-hour format)
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${day}.${month}.${year} à ${hours}h${minutes}`;
};

const CalendarPage = ({ matches }) => {
  const [filteredMatches, setFilteredMatches] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth()); // Default to the current month
  const navigate = useNavigate();

  const months = [
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
  ];

  const getSeasonMonthIndex = (monthIndex) => (monthIndex + 6) % 12; // Shift months to start at July (index 6)

  useEffect(() => {
    const currentMonth = new Date().getMonth();
    const adjustedMonth = getSeasonMonthIndex(currentMonth);
    setSelectedMonth(adjustedMonth);
  }, []);

  useEffect(() => {
    const filtered = matches.filter((match) => {
      const matchMonth = new Date(match.matchDate).getMonth();
      return getSeasonMonthIndex(matchMonth) === selectedMonth;
    });

    const sorted = filtered.sort(
      (a, b) => new Date(a.matchDate) - new Date(b.matchDate)
    );
    setFilteredMatches(sorted);
  }, [matches, selectedMonth]);

  const handleMatchClick = (matchId) => {
    navigate(`/calendrier/${matchId}`);
  };  

  return (
    <div>
      <SectionHeader title="Calendrier" />
      <div className="container mx-auto px-4 py-8">
        {/* Month Selector */}
        <div className="flex justify-center mb-8 flex-wrap">
          {months.map((month, index) => (
            <button
              key={index}
              onClick={() => setSelectedMonth(index)}
              className={`px-4 py-2 mx-1 my-1 rounded ${
                selectedMonth === index
                  ? "bg-primary text-white"
                  : "bg-gray-200 hover:bg-primary hover:text-white"
              }`}
            >
              {month}
            </button>
          ))}
        </div>

        {/* Matches */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredMatches.length > 0 ? (
            filteredMatches.map((match) => (
              <div
                key={match.matchId}
                className={`border rounded-lg shadow-lg overflow-hidden ${
                  match.matchState === 2 ? "cursor-pointer" : ""
                }`}
                onClick={match.matchState === 2 ? () => handleMatchClick(match.matchId) : null}
              >
                {/* Card Header */}
                <div className="bg-primary text-white p-6">
                  <div className="grid grid-cols-3 items-center text-center">
                    {/* Team A */}
                    <div>
                      <img
                        src={getTeamLogo(match.teamNameA)} // Use the utility to get the logo
                        alt={match.teamNameA}
                        className="w-16 h-16 mx-auto mb-2"
                        onError={(e) =>
                          (e.target.src = "/team-logos/default.png")
                        } // Fallback to default logo
                      />
                      <p
                        className="text-lg font-bold leading-tight break-words h-12 flex items-center justify-center"
                        style={{ lineHeight: "1.2rem" }}
                      >
                        {match.teamNameA}
                      </p>
                    </div>
                    {/* Score */}
                    <div>
                      <h2 className="text-4xl font-extrabold">
                        {match.matchState === 2
                          ? `${match.scoreTeamA} - ${match.scoreTeamB}`
                          : "VS"}
                      </h2>
                    </div>
                    {/* Team B */}
                    <div>
                      <img
                        src={getTeamLogo(match.teamNameB)} // Use the utility to get the logo
                        alt={match.teamNameB}
                        className="w-16 h-16 mx-auto mb-2"
                        onError={(e) =>
                          (e.target.src = "/team-logos/default.png")
                        } // Fallback to default logo
                      />
                      <p
                        className="text-lg font-bold leading-tight break-words h-12 flex items-center justify-center"
                        style={{ lineHeight: "1.2rem" }}
                      >
                        {match.teamNameB}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Card Content */}
                <div className="bg-white p-4">
                  {/* Translate matchTypeName */}
                  <p className="text-sm font-bold text-gray-600">
                    {matchTypeTranslations[match.matchTypeName] ||
                      match.matchTypeName}
                  </p>
                  {/* Translate leagueName */}
                  <h3 className="text-lg font-bold">
                    {leagueNameTranslations[match.leagueName] ||
                      match.leagueName}
                  </h3>
                  <p className="text-sm text-primary font-bold">
                    {matchStateTranslations[match.matchStateName] ||
                      match.matchStateName}
                  </p>
                  <div className="mt-2 flex items-center text-sm text-gray-600">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-5 h-5 mr-1"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M8 7V3m8 4V3m-9 4h10a2 2 0 012 2v10a2 2 0 01-2 2H7a2 2 0 01-2-2V9a2 2 0 012-2z"
                      />
                    </svg>
                    {/* Translate playDayName */}
                    {playDayTranslations[match.playDayName] ||
                      match.playDayName}
                    , {formatDate(match.matchDate)}
                  </div>

                  <div className="mt-1 flex items-center text-sm text-gray-600">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-5 h-5 mr-1"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 7.5l4 4m0 0l-4 4m4-4H4"
                      />
                    </svg>
                    {match.stadiumPlaygroundName}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center text-gray-600">
              Aucun match trouvé pour ce mois-ci !
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CalendarPage;
